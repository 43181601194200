@import "shared";

.top-margin {
    margin-top: 25px;
    @media screen and (min-width: 557px) {
        margin-top: 150px;
    }
}

.home-header {
    img {
        width: 85%;
        display: block;
        margin: auto;
    }

    @media screen and (min-width: 557px) {
        img {
            margin: 0;
        }
    }
}

.bird {
    #bird {
        width: 90%;
        display: block;
        margin: auto;
        margin-top: 5%;
    }
    video {
        margin-top: 59%;
    }
}

.portfolio-section {
    
    @media screen and (max-width: 767px) {
        background-image: url('/site/assets/images/Mobile-BG.png');
    }

    @media screen and (min-width: 768px) {
        background-image: url('/site/assets/images/home_gradient.png');
    }

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;

    // tablet
    @media screen and (min-height: 1024px) {
        min-height: 0px; // override 100vh 
    }

    .row {
        padding: 100px 0px;

        @media screen and (min-width: 769px) {
            padding: 150px 0px;
        }
    }

    .portfolio-header {
        font-family: 'Graphik-Bold';
        font-size: 45px;
        position: relative;
        margin-bottom: 50px;

        @media screen and (min-width: 576px) {
            font-size: 50px;
        }

        @media screen and (min-width: 1200px) {
            font-size: 62px;
        }
    }

    .contact-btn {
        font-family: 'SFMono-Bold';
        font-size: 18px;
        background-color: transparent;
        border: 2px solid rgba(255, 255, 255, 0.6);
        color: white;
        padding: 8px 20px;
        margin-bottom: 50px;
        cursor: pointer;
        outline: 0;

        @media screen and (min-width: 767px) {
            margin-bottom: 0px;
        }

        &:hover {
            border: 2px solid white;
        }
    }

    .opacity {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 21px 0px;

        .d-flex {
            margin: 21px 0px;

            @media screen and (min-width: 767px) {
                margin: 0px 0px;
            }
        }
    }

    .company-logo {
        width: 33%;

        &.accenture {
            width: 40%;
        }

        &.warner {
            width: 20%;
        }

        &.scandy {
            width: 42%;
        }

        &.aftp {
            width: 21%;
        }
    }

    video {
        width: 100%;
        border: 8px solid rgba(255, 255, 255, 0.2);

        @media screen and (min-width: 767px) {
            width: 95%;
        }
    }

}


.capabilities-section {
    margin-top: 100px;
    padding-bottom: 55px;
    position: relative;

    h2 {
        font-family: "Graphik-Bold";
        text-align: center;
        font-size: 42px;
        margin-bottom: 50px;

        @media screen and (min-width: 576px) {
            font-size: 60px;
        }
    }

    .text-center {
        font-family: 'SFMono-Regular';
        color: lightgray;
        font-size: 22px;
        margin-bottom: 50px;
    }

    .corner-img {
        position: absolute;

        &.top {
            top: 70px;

            @media screen and (min-width: 576px) {
                top: 30px;
            }
        }
        &.bottom {
            bottom: 0;
        }
        &.left {
            left: 10px;
        }
        &.right {
            right: 10px;
        }
    }

}



.story-section {
    text-align: center;
    background-image: url('/site/assets/images/austin_story_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px; 

    &:hover {
        cursor: pointer;
    }

    .centered-scaling-text {
        position: relative;
        bottom: -114px;
    }

    a:hover {
        text-decoration-color: white;
    }

    .story-header {
        font-family: 'Graphik-Semibold';
        font-size: 28px;
        margin-bottom: 21px;

        @media screen and (min-width: 576px) {
            font-size: 65px;
        }
    }

    .story-description {
        font-family: 'Graphik-Bold';
        color: rgba(255, 255, 255, .8);
        font-size: 21px;

        @media screen and (min-width: 576px) {
            font-size: 35px;
        }
    }
}

#contactModal {

    .modal-content {
        background-color: #222;
        color: gray;
    }

    .modal-header {
        border: 0px solid black;
        justify-content: center;

        h4 {
            font-family: 'Graphik-Bold';
            color: lightgray;
        }

        .close {
            position: absolute;
            right: 21px;
            top: 19px;
        }

        span {
            color: white;
        }
    }

    p {
        font-family: 'Graphik-SemiBold';
    }

    .form-control {
        background-color: #111 !important;
        border: 1px solid black;
        color: white  !important;
    }

    .modal-body {

        button {
            font-family: 'Graphik-Bold';
            background-color: transparent;
            color: snow;
            border: 2px solid rgba(255, 255, 255, 0.3);
            width: 175px;
            height: 50px;
            cursor: pointer;

            &:hover {
                border: 2px solid white;
            }
        }

        span {
            color: #203d90;
        }
    }

}


