@font-face {
    font-family: 'Graphik-Bold';
    src: url('/site/assets/fonts/Graphik-Bold.eot?#iefix') format('embedded-opentype'),  url('/site/assets/fonts/Graphik-Bold.otf')  format('opentype'),
           url('/site/assets/fonts/Graphik-Bold.woff') format('woff'), url('/site/assets/fonts/Graphik-Bold.ttf')  format('truetype'), url('/site/assets/fonts/Graphik-Bold.svg#Graphik-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Graphik-Semibold';
    src: url('/site/assets/fonts/Graphik-Semibold.eot?#iefix') format('embedded-opentype'),  url('/site/assets/fonts/Graphik-Semibold.otf')  format('opentype'),
         url('/site/assets/fonts/Graphik-Semibold.woff') format('woff'), url('/site/assets/fonts/Graphik-Semibold.ttf')  format('truetype'), url('/site/assets/fonts/Graphik-Semibold.svg#Graphik-Semibold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Graphik-Light';
    src: url('/site/assets/fonts/Graphik-Light.eot?#iefix') format('embedded-opentype'),  url('/site/assets/fonts/Graphik-Light.otf')  format('opentype'),
           url('/site/assets/fonts/Graphik-Light.woff') format('woff'), url('/site/assets/fonts/Graphik-Light.ttf')  format('truetype'), url('/site/assets/fonts/Graphik-Light.svg#Graphik-Light') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  

@font-face {
    font-family: 'SFMono-Bold';
    src: url('/site/assets/fonts/SFMono-Bold.eot?#iefix') format('embedded-opentype'),  url('/site/assets/fonts/SFMono-Bold.otf')  format('opentype'),
           url('/site/assets/fonts/SFMono-Bold.woff') format('woff'), url('/site/assets/fonts/SFMono-Bold.ttf')  format('truetype'), url('/site/assets/fonts/SFMono-Bold.svg#SFMono-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: 'SFMono-Regular';
    src: url('/site/assets/fonts/SFMono-Regular.eot?#iefix') format('embedded-opentype'),  url('/site/assets/fonts/SFMono-Regular.otf')  format('opentype'),
           url('/site/assets/fonts/SFMono-Regular.woff') format('woff'), url('/site/assets/fonts/SFMono-Regular.ttf')  format('truetype'), url('/site/assets/fonts/SFMono-Regular.svg#SFMono-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SFMono-Light';
    src: url('/site/assets/fonts/SFMono-Light.eot?#iefix') format('embedded-opentype'),  url('/site/assets/fonts/SFMono-Light.otf')  format('opentype'),
           url('/site/assets/fonts/SFMono-Light.woff') format('woff'), url('/site/assets/fonts/SFMono-Light.ttf')  format('truetype'), url('/site/assets/fonts/SFMono-Light.svg#SFMono-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SFMono-Medium';
    src: url('/site/assets/fonts/SFMono-Medium.eot?#iefix') format('embedded-opentype'),  url('/site/assets/fonts/SFMono-Medium.otf')  format('opentype'),
           url('/site/assets/fonts/SFMono-Medium.woff') format('woff'), url('/site/assets/fonts/SFMono-Medium.ttf')  format('truetype'), url('/site/assets/fonts/SFMono-Medium.svg#SFMono-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

body {
    background-color: black;
    font-family: Helvetica, Arial, sans-serif;
    
}
html,body {
    max-width: 100%;
    overflow-x: hidden;
}

// section headers
h2 {
    color: white;
}

// paragraph headers
h3 {
    color: white;
}

// section description
h4 {
    color: white;
}

// paragraph description
h5 {
    color: white;
}

.right-aligned {
    text-align: right;
}

.no-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.break_out{
  width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
}

// Used to outline top section of each page
.white-border {
    border: 14px solid white;
    height: 100vh;
    max-height: 1100px;
    position: relative;
    overflow: hidden;

    h1{
        font-weight: bold;
        font-size: 11vw;
        color: #FFFFFF;
        letter-spacing: 0;
        position: relative;
        z-index: 1;
        @media screen and (min-width: 576px) {
            font-size: 10vw;
        }
        @media screen and (min-width: 1200px) {
            font-size: 8vw;
        }
    }
}

.white-border:before {
    content:"";
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    z-index:0;
    background:rgba(0, 0, 0, 0.6);
  }

// navbar
.nav-link {
    font-family: 'SFMono-Regular';
    color: gray;

    &.active {
        color: white;
    }
}
.navbar-toggler {
    &:focus {
        outline: none;
    }
}



//hero
.page-header {
    font-family: 'Graphik-Bold';
    top: 5vh;
}

.video_hero {
    @media screen and (max-width: 576px) {
       width: 112%;
       position: relative;
       top: 100px;
       left: -15px;
    }
    @media screen and (min-width: 576px) {
        position: absolute;
        z-index: -1;
        min-width: 100%;
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}
  

// styles in-paragraph links
.inline-link {
    color: inherit;
    text-decoration: underline;

    &:hover {
        color: white;
    }
}

.product_text{
    margin: auto;
}

// block underlining headers 
.underline-block {
    height: 21px;
    width: 15%;
    max-width: 140px;
    margin-top: 21px;
    margin-bottom: 21px;
    position: relative;

    &.white {
        background-color: white;
    }
    &.pink {
        background-color: #DE135B;
        display: none;

        @media screen and (min-width: 576px) {
            display: block;
        }
    }

    &.special {
        top: 60px;
    }
}




// footer
.footer {
    text-align: center;
    max-width: 500px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 100px;

    img {
        width: 130px;
    }


    .lightbar {
        width: 80%;
        height: 3px;
        margin: auto;
        margin-top: 21px;
        
        &.blue {
            background: #3331ED; 
        }

        &.pink {
            background: #CC33D1;
        }
    }

    .copyright {
        font-family: 'SFMono-Bold';
        color: darkgray;
        margin-top: 20px;
    }

    .contact-us {
        font-family: "SFMono-Regular";
        color: white;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

}

